import React, { Component } from "react";
import { withRouter } from "react-router";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import appState from "../App.state";
import * as surveyService from "../services/survey";
import * as vernacularService from "../services/vernacular";
import "./Authenticate.css";

export default withRouter(
  class Authenticate extends Component {
    newAdminPasscode = "";

    handleAdminPasscodeInputChange = event => {
      this.newAdminPasscode = event.target.value;
    };

    handleApplyButtonClick = event => {
      event.preventDefault();

      appState.addPromise(
        surveyService
          .validateAdminPasscode(this.newAdminPasscode)
          .then(isValid => {
            if (isValid) {
              appState.adminPasscode = this.newAdminPasscode;
              this.props.history.push("/");
            } else {
              throw new Error("Invalid passcode");
            }
          })
          // Warm up survey vernacular cache
          .then(() => vernacularService.getSurveyVernacular())
      );
    };

    render() {
      return (
        <div className="Authenticate">
          <Jumbotron>
            <Container>
              <h3>
                <span
                  className="oi oi-lock-locked protected-icon"
                  title="Edit"
                  aria-hidden="true"
                />
                Protected access
              </h3>
            </Container>
          </Jumbotron>
          <Container>
            <form>
              <div className="form-group">
                <label htmlFor="adminPasscodeInput">Admin passcode</label>
                <input
                  type="text"
                  id="adminPasscodeInput"
                  className="form-control"
                  onChange={this.handleAdminPasscodeInputChange}
                />
                <small id="adminPasscodeHelp" className="form-text text-muted">
                  Ask your manager to obtain the passcode
                </small>
              </div>
              <button
                className="btn btn-primary"
                onClick={this.handleApplyButtonClick}
              >
                Apply
              </button>
            </form>
          </Container>
        </div>
      );
    }
  }
);
