import * as mobx from "mobx";

export default mobx.observable({
  _currentError: null,
  get currentError() {
    return this._currentError;
  },
  set currentError(err) {
    this._currentError = err;
    setTimeout(() => {
      if (this._currentError === err) {
        this._currentError = null;
      }
    }, 5000); // Must match css animation duration + fadeout duration
  },
  _pendingPromises: [],
  get isLoading() {
    return this._pendingPromises.length > 0;
  },
  addPromise(promise, ignoreError = false) {
    this._pendingPromises.push(promise);
    return promise
      .catch(err => {
        if (!ignoreError) {
          this.currentError = err;
        }
      })
      .finally(() => {
        this._pendingPromises = this._pendingPromises.filter(
          p => p !== promise
        );
      });
  },
  _adminPasscode: localStorage.getItem("adminPasscode"),
  get adminPasscode() {
    return this._adminPasscode || "";
  },
  set adminPasscode(passcode) {
    this._adminPasscode = passcode;
    localStorage.setItem("adminPasscode", passcode);
  },
  get isAuthenticated() {
    return this.adminPasscode !== "";
  }
});
