/**
 * @param {string} filename
 * @param {ArrayBuffer} data
 * @param {object} options
 * @param {string} options.type
 */
export function downloadDataAsFile(filename, data, { type }) {
  const blob = new Blob([data], { type });
  const downloadURL = URL.createObjectURL(blob);
  const dowbloadLink = document.createElement("a");
  dowbloadLink.href = downloadURL;
  dowbloadLink.setAttribute("download", filename);
  dowbloadLink.click();
  URL.revokeObjectURL(downloadURL);
}
