import max from "lodash/max";
/**
 * @typedef {import("survey-knockout").ISurveyElement} ISurveyElement
 * @typedef {import("survey-knockout").SurveyModel} SurveyModel
 * @typedef {import("mcg-survey-rest-api").Element} Element
 * @typedef {import("mcg-survey-rest-api").SurveyVernacular} SurveyVernacular
 */

/**
 * Mapping of SurveyJS question types to MCG question types
 * @type {Record<string, string>}
 */
export const questionTypes = {
  text: "Regular textbox",
  multipletext: "Textbox grid",
  comment: "Large textbox",
  checkbox: "Checkbox",
  radiogroup: "Radiogroup",
  matrix: "Radiogroup grid",
  matrixdropdown: "Checkbox grid",
  discreteslider: "Slider",
  sortablelist: "Rankings",
  panel: "Group"
};

/**
 * @param {object} question
 * @param {string} question.methodology
 * @param {string} question.capability
 * @param {string} question.indicator
 * @param {string} [question.postfix]
 * @param {SurveyVernacular} vernacular
 * @returns {object}
 */
export function reconcileQuestionWithVernacular(
  { methodology, capability, indicator },
  vernacular
) {
  const { methodologies } = vernacular;
  const { capabilities } = methodologies.find(m => m.name === methodology);
  const { indicators, name: cap } =
    capabilities.find(c => c.name === capability) || capabilities[0];
  const { name: ind } =
    indicators.find(i => i.name === indicator) || indicators[0];
  return {
    methodology,
    capability: cap,
    indicator: ind
  };
}

/**
 * @param {object} question
 * @param {string} question.methodology
 * @param {string} question.capability
 * @param {string} question.indicator
 * @param {SurveyVernacular} vernacular
 * @returns {string}
 */
export function getQuestionCode(
  { methodology, capability, indicator, postfix = "" },
  vernacular
) {
  const { methodologies } = vernacular;
  const { capabilities } = methodologies.find(m => m.name === methodology);
  const { indicators } = capabilities.find(c => c.name === capability);
  const { shorthand: cap } = capabilities.find(c => c.name === capability);
  const { shorthand: ind } = indicators.find(i => i.name === indicator);

  if (cap === "") {
    return `${methodology}_${ind}${postfix}`;
  } else {
    return `${methodology}_${cap}_${ind}${postfix}`;
  }
}

/**
 * @param {SurveyModel} survey
 * @param {object} question
 * @param {string} question.methodology
 * @param {string} question.capability
 * @param {string} question.indicator
 * @returns {string}
 */
export function getNextQuestionPostfix(
  survey,
  { methodology, capability, indicator }
) {
  const pattern = /^_q(\d+)b1/;
  const allRelatedQuestions = survey
    .getAllQuestions()
    .filter(
      q =>
        isSurveyJSQuestion(q) &&
        q.methodology === methodology &&
        q.capability === capability &&
        q.indicator === indicator &&
        pattern.test(q.postfix)
    );
  if (allRelatedQuestions.length === 0) {
    return "_q1b1";
  } else {
    const highestQn = max(
      allRelatedQuestions.map(q => parseInt(q.postfix.match(pattern)[1]))
    );
    return `_q${highestQn + 1}b1`;
  }
}

/**
 * @param {SurveyModel} survey
 * @param {string} prefix
 * @returns {string}
 */
export function getNextQuestionNumber(survey, prefix = "Q") {
  const pattern = new RegExp(`^${prefix}(\\d+)`);
  const allQuestionsWithPrefix = survey
    .getAllQuestions()
    .filter(q => isSurveyJSQuestion(q) && pattern.test(q.questionNumber));
  if (allQuestionsWithPrefix.length === 0) {
    return `${prefix}1`;
  } else {
    const highestQuestionNumber = max(
      allQuestionsWithPrefix.map(q =>
        parseInt(q.questionNumber.match(pattern)[1])
      )
    );
    return `${prefix}${highestQuestionNumber + 1}`;
  }
}

/**
 * @param {string} questionNumber
 * @param {string} oldPrefix
 * @param {string} newPrefix
 * @returns {string}
 */
export function replaceQuestionNumberPrefix(
  questionNumber,
  oldPrefix,
  newPrefix
) {
  const pattern = new RegExp(`^${oldPrefix}(\\d+)`);
  return questionNumber.replace(pattern, `${newPrefix}$1`);
}

/**
 * @param {Element} element
 * @returns {boolean}
 */
export function isQuestion(element) {
  return element.type !== "panel" && element.type !== "placeholdergroup";
}

/**
 * @param {ISurveyElement} element
 * @returns {boolean}
 */
export function isSurveyJSQuestion(element) {
  return (
    element.getType() !== "panel" && element.getType() !== "placeholdergroup"
  );
}
