export default {
  ed: {
    designer: "Editor",
    testSurveyAgain: "Restart Survey",
    testSurvey: "Preview"
  },
  pe: {
    defaultValue: "Placeholder Values"
  },
  p: {
    defaultValue: "Placeholder Values"
  }
};
