import React, { Component } from "react";
import { withRouter } from "react-router";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import yaml from "yaml";
import appState from "../App.state";
import * as vernacularService from "../services/vernacular";
import { downloadDataAsFile } from "../lib/io";
import "./SurveyVernacularEditor.css";

/**
 * @typedef {import("mcg-survey-rest-api").SurveyVernacular} SurveyVernacular
 */

export default withRouter(
  class SurveyVernacularEditor extends Component {
    constructor(props) {
      super(props);
      this.state = {
        /**
         * @type {SurveyVernacular}
         */
        vernacularYaml: ""
      };
    }

    componentDidMount() {
      appState.addPromise(
        vernacularService.getSurveyVernacular().then(vernacular => {
          this.setState({
            vernacularYaml: yaml.stringify(vernacular)
          });
        })
      );
    }

    handleChange = event => {
      this.setState({ vernacularYaml: event.target.value });
    };

    handleSaveClick = event => {
      event.preventDefault();
      const vernacular = yaml.parse(this.state.vernacularYaml);
      appState.addPromise(
        vernacularService.upsertSurveyVernacular(vernacular).then(() => {
          this.props.history.push("/");
        })
      );
    };

    handleDownloadClick = event => {
      event.preventDefault();
      downloadDataAsFile("survey_vernacular.yaml", this.state.vernacularYaml, {
        type: "text/x-yaml"
      });
    };

    render() {
      return (
        <div className="SurveyVernacularEditor">
          <Jumbotron>
            <Container>
              <h3>Survey Vernacular</h3>
              <p>Below you can edit survey vernacular in YAML format</p>
            </Container>
          </Jumbotron>
          <Container className="table-responsive vernacular-container">
            <Form onSubmit={this.handleSaveClick}>
              <ButtonGroup className="action-bar">
                <Button variant="primary" type="submit">
                  Save
                </Button>
                <Button variant="secondary" onClick={this.handleDownloadClick}>
                  <span
                    className="oi oi-data-transfer-download"
                    title="Download"
                    aria-hidden="true"
                  />
                </Button>
              </ButtonGroup>
              <Form.Group>
                <Form.Control
                  value={this.state.vernacularYaml}
                  onChange={this.handleChange}
                  as="textarea"
                  rows="24"
                />
              </Form.Group>
            </Form>
          </Container>
        </div>
      );
    }
  }
);
