import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import "jquery-bar-rating";
import {
  SurveyCreator,
  SurveyQuestionEditorDefinition,
  defaultStrings,
  removeAdorners
} from "survey-creator";
import * as SurveyKo from "survey-knockout";
import * as widgets from "surveyjs-widgets";
import merge from "lodash/merge";
import isEqual from "lodash/isEqual";
import uuid from "uuid/v4";

import {
  getQuestionCode,
  getNextQuestionNumber,
  questionTypes,
  reconcileQuestionWithVernacular,
  isSurveyJSQuestion,
  replaceQuestionNumberPrefix,
  getNextQuestionPostfix
} from "../lib/surveyUtils";
import { ConflictingQuestionError } from "../services/survey";
import * as surveyService from "../services/survey";
import * as vernacularService from "../services/vernacular";
import appState from "../App.state";
import strings from "./SurveyEditor.strings";
import {
  additionalPropertiesByObjectType,
  additionalModalEditorPropertiesByObjectTypeAndTab,
  hiddenPropertiesByObjectType,
  excludedPropertiesByObjectType
} from "./SurveyEditor.props";
import "./SurveyEditor.theme";
import { ConfirmationDialog } from "../components/ConfirmationDialog";
import discreteSlider from "../widgets/discreteSlider";

removeAdorners(["title", "select-choices"]);
widgets.sortablejs(SurveyKo);
widgets.jquerybarrating(SurveyKo);
discreteSlider(SurveyKo, $);

// Warm up survey vernacular cache (ignoring errors)
appState.addPromise(vernacularService.getSurveyVernacular(), true);

// Make matrix checkboxes default to false instead of "indeterminate"
SurveyKo.Serializer.findProperty("boolean", "defaultValue").defaultValue =
  "false";
// Hide default question numbers
SurveyKo.Serializer.findProperty("survey", "showQuestionNumbers").defaultValue =
  "false";
// Make question code read-only
SurveyKo.Serializer.findProperty("questionbase", "name").readOnly = true;

// TODO: Add custom toolbox items in a data-driven way
SurveyKo.Serializer.addClass(
  "placeholdergroup",
  [
    { name: "methodology", visible: false },
    { name: "capability", visible: false },
    { name: "indicator", visible: false },
    { name: "notes", visible: false },
    { name: "postfix", visible: false },
    { name: "description", visible: false },
    { name: "visible", visible: false, defaultValue: false },
    { name: "questionNumber", visible: false }
  ],
  null,
  "multipletext"
);

SurveyQuestionEditorDefinition.definition.placeholdergroup = {
  tabs: [],
  properties: [
    {
      name: "defaultValue",
      tab: "general"
    }
  ]
};
// Change default strings
merge(defaultStrings, strings);

// Exclude superfluous survey, page, panel, and question properties
Object.entries(excludedPropertiesByObjectType).forEach(
  ([className, properties]) =>
    properties.forEach(property =>
      SurveyKo.Serializer.removeProperty(className, property)
    )
);
// Hide superfluous survey, page, panel, and question properties
Object.entries(hiddenPropertiesByObjectType).forEach(
  ([className, properties]) =>
    properties.forEach(property => {
      const p = SurveyKo.Serializer.findProperty(className, property);
      if (!!p) {
        p.visible = false;
      }
    })
);

// Add custom survey and question properties
Object.entries(additionalPropertiesByObjectType).forEach(
  ([className, properties]) => {
    SurveyKo.Serializer.addProperties(className, properties);
  }
);
// Populate survey and question modal editors with additional properties
Object.entries(additionalModalEditorPropertiesByObjectTypeAndTab).forEach(
  ([className, propertiesByTab]) => {
    const modalEditor = SurveyQuestionEditorDefinition.definition[
      className
    ] || {
      tabs: [],
      properties: []
    };
    Object.entries(propertiesByTab).forEach(([tab, properties]) => {
      modalEditor.properties.push(...properties.map(name => ({ tab, name })));
    });
    SurveyQuestionEditorDefinition.definition[className] = modalEditor;
  }
);

class SurveyEditor extends Component {
  /**
   * @type {SurveyCreator}
   */
  widget;
  /**
   * @type {string}
   */
  id;

  state = {
    showForceSaveConfirmationModal: false,
    confirmationMessage: null
  };

  handleForceSaveCancellation = () => {
    this.setState({
      showForceSaveConfirmationModal: false,
      confirmationMessage: null
    });
  };

  handleForceSaveConfirmation = () => {
    this.setState({
      showForceSaveConfirmationModal: false,
      confirmationMessage: null
    });
    this.saveSurvey({ force: true });
  };

  promptForceSaveConfirmation = message => {
    this.setState({
      showForceSaveConfirmationModal: true,
      confirmationMessage: message
    });
  };

  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
  }

  saveSurvey = ({ force = false } = {}) => {
    const model = JSON.parse(this.widget.text);
    appState.addPromise(
      surveyService
        .upsertSurvey(this.id, model, {}, force)
        .then(() => {
          // Save a snapshot of survey prior to the edits
          if (
            this.surveySnapshot &&
            !isEqual(this.surveySnapshot.model, model)
          ) {
            surveyService.upsertSurvey(
              uuid(),
              {
                ...this.surveySnapshot.model,
                name: `${this.surveySnapshot.model.name} Snapshot`
              },
              { deleted: "true" }
            );
          }
        })
        .then(() => {
          this.surveySnapshot = { model };
        })
        .catch(err => {
          if (err instanceof ConflictingQuestionError) {
            this.promptForceSaveConfirmation(err.message);
          } else {
            throw err;
          }
        })
    );
  };

  componentDidMount() {
    this.widget = new SurveyCreator("surveyEditorContainer", {
      questionTypes: Object.keys(questionTypes)
    });
    this.widget.saveSurveyFunc = this.saveSurvey;
    this.widget.haveCommercialLicense = true;

    // Make the 'defaultValue' property only visible for the 'placeholdergroups'
    // question type
    this.widget.onCanShowProperty.add((_, options) => {
      if (options.property && options.property.name === "defaultValue") {
        options.canShow = options.obj.getType() === "placeholdergroup";
      }
    });

    // Programatically set default property values to get around this issue
    // https://surveyjs.answerdesk.io/ticket/details/t2245/property-default-values-aren-t-serialized
    this.widget.survey.pages[0].questionNumberPrefix = "Q";
    this.widget.onPageAdded.add((_, { page }) => {
      if (!page.questionNumberPrefix) {
        page.questionNumberPrefix = "Q";
      }
    });
    this.widget.onPanelAdded.add((_, { panel }) => {
      if (!panel.questionNumberPrefix) {
        panel.questionNumberPrefix = "Q";
      }
    });
    this.widget.onQuestionAdded.add((_, { question }) => {
      if (!question.methodology) {
        question.methodology = "OPA";
      }
      if (!question.capability) {
        question.capability = "Custom";
      }
      if (!question.indicator) {
        question.indicator = "Custom";
      }
    });

    // Programatically set the question postfix, question name, and question number
    this.widget.onQuestionAdded.add(async (_, { question }) => {
      const vernacular = await vernacularService.getSurveyVernacular();
      if (question.getType() === "placeholdergroup") {
        question.name = "$";
        question.questionNumber = "$";
      } else {
        // Avoid having duplicate postfixes for the default question methodology,
        // capability, and indicator
        question.postfix = getNextQuestionPostfix(this.widget.survey, {
          methodology: question.methodology,
          capability: question.capability,
          indicator: question.indicator
        });
        question.name = getQuestionCode(question, vernacular);
        question.questionNumber =
          question.questionNumber ||
          getNextQuestionNumber(
            this.widget.survey,
            question.parent.questionNumberPrefix
          );
      }
    });

    this.widget.onPropertyValueChanging.add(
      async (_, { obj, propertyName, newValue }) => {
        if (obj instanceof SurveyKo.Question) {
          // Update question code if methodology, capability, indicator or postfix
          // has changed
          const pk = ["methodology", "capability", "indicator", "postfix"];
          if (pk.includes(propertyName)) {
            const vernacular = await vernacularService.getSurveyVernacular();
            const question = pk.reduce(
              (q, p) => ({ ...q, [p]: p === propertyName ? newValue : obj[p] }),
              {}
            );
            const q = reconcileQuestionWithVernacular(question, vernacular);
            obj.capability = q.capability;
            obj.indicator = q.indicator;
            obj.name = getQuestionCode({ ...question, ...q }, vernacular);
          }
        } else if (
          (obj instanceof SurveyKo.Page ||
            obj instanceof SurveyKo.PanelModel) &&
          propertyName === "questionNumberPrefix"
        ) {
          // Update question numbers of panel elements if question number prefix
          // has changed
          obj.elements
            .filter(e => isSurveyJSQuestion(e))
            .forEach(q => {
              q.questionNumber = replaceQuestionNumberPrefix(
                q.questionNumber,
                obj.questionNumberPrefix,
                newValue
              );
            });
        }
      }
    );

    // TODO: Add/edit custom toolbox items in a data-driven way
    this.widget.toolbox.addItem({
      name: "discreteslider",
      iconName: "icon-html",
      title: "Slider",
      json: {
        type: "discreteslider",
        choices: ["1", "2", "3", "4", "5", "6", "7"]
      }
    });
    this.widget.toolbox.removeItem("matrixdropdown");
    this.widget.toolbox.addItem({
      name: "matrixdropdown",
      iconName: "icon-matrixdropdown",
      title: "Checkbox grid",
      json: {
        type: "matrixdropdown",
        cellType: "boolean",
        columns: [
          {
            name: "col1",
            title: "Column 1"
          },
          {
            name: "col2",
            title: "Column 2"
          },
          {
            name: "col3",
            title: "Column 3"
          }
        ],
        rows: ["Row 1", "Row 2"]
      }
    });

    // Use MCG vocabulary in toolbox
    Object.entries(questionTypes).forEach(([typeName, title]) => {
      const questionType = this.widget.toolbox.getItemByName(typeName);
      questionType.title = title;
    });

    this.widget.toolbox.addItem({
      name: "placeholdergroup",
      iconName: "icon-default",
      title: "Placeholder Group",
      json: {
        type: "placeholdergroup",
        title: "Placeholders",
        items: [{ name: "customer", title: "Customer" }],
        defaultValue: { customer: "<REPLACE_ME>" },
        visible: false
      }
    });

    // Display custom question numbers
    const questionTitleTemplate = "{questionNumber}. {title}";
    this.widget.survey.questionTitleTemplate = questionTitleTemplate;

    appState.addPromise(
      surveyService.getSurvey(this.id).then(survey => {
        if (survey) {
          this.surveySnapshot = { ...survey };
          this.widget.changeText(
            JSON.stringify({ ...survey.model, questionTitleTemplate }),
            true
          );
        }
        // Get around this issue
        // https://surveyjs.answerdesk.io/ticket/details/t2246/surveyjs-json-schema
        if (!this.widget.survey.name) {
          this.widget.survey.name = "Unnamed Survey";
        }
      })
    );
  }

  render() {
    return [
      <div id="surveyEditorContainer" />,
      <ConfirmationDialog
        show={this.state.showForceSaveConfirmationModal}
        title="Conflicting questions"
        body={this.state.confirmationMessage}
        callToAction="Do you want to continue and overwrite the master question list?"
        cancelLabel="Cancel"
        confirmLabel="Overwrite"
        onCancel={this.handleForceSaveCancellation}
        onConfirm={this.handleForceSaveConfirmation}
      />
    ];
  }
}

export default withRouter(SurveyEditor);
