import { Workbook } from "exceljs";
import { SurveyModel } from "survey-knockout";

/**
 * @typedef {import("mcg-survey-rest-api").Survey} Survey
 * @typedef {import("survey-knockout").IQuestion} IQuestion
 * @typedef {import("./workbookFactory.d").AnswerDescriptor} AnswerDescriptor
 */

/**
 * @param {Survey} survey
 * @returns {Workbook}
 */
export function createSurveyWorkbook(survey) {
  const surveyContainer = new SurveyModel(survey.model);
  const workbook = new Workbook();
  const answerSheet = workbook.addWorksheet("AnswerSheet");
  const metadataSheet = workbook.addWorksheet("Metadata");
  answerSheet.columns = [
    { header: "Page Number", key: "pageNumber", width: 11 },
    { header: "Question Type", key: "questionType", width: 18 },
    { header: "Question Code", key: "questionCode", width: 18 },
    { header: "Question Number", key: "questionNumber", width: 15 },
    { header: "Question Text", key: "questionText", width: 80 },
    { header: "Answer Code", key: "answerCode", width: 22 },
    { header: "Answer Item", key: "answerItem", width: 22 },
    { header: "Answer Number", key: "answerNumber", width: 15 },
    { header: "Answer Options", key: "answerOptions", width: 22 },
    { header: "Answer Notes", key: "answerNotes", width: 40 },
    { header: "Methodology", key: "methodology", width: 11 },
    { header: "Capability", key: "capability", width: 18 },
    { header: "Indicator", key: "indicator", width: 18 },
    { header: "Ancestor", key: "ancestor", width: 18 },
    { header: "Notes", key: "notes", width: 40 }
  ];
  metadataSheet.columns = [
    { header: "Name", key: "name", width: 22 },
    { header: "Title", key: "title", width: 40 },
    { header: "Customer", key: "customer", width: 22 },
    { header: "Notes", key: "notes", width: 40 }
  ];
  // Render placeholder values in answer sheet
  const renderedAnswerSheet = survey.answerSheet.map(row => {
    if (row.questionType !== "panel") {
      const q = surveyContainer.getQuestionByName(row.questionCode);
      return { ...row, questionText: q.locDescription.calculatedText };
    } else {
      return row;
    }
  });
  answerSheet.addRows(renderedAnswerSheet);
  metadataSheet.addRows([survey.model]);
  return workbook;
}
