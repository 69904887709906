import "jquery-bar-rating/dist/themes/bars-movie.css";
import "./discreteSlider.css";
export default (Survey, $ = window.$) => {
  Survey.CustomWidgetCollection.Instance.addCustomWidget(
    {
      name: "discreteslider",
      title: "Discrete slider",
      iconName: "icon-html",
      widgetIsLoaded: () => {
        return typeof $ == "function" && !!$.fn.barrating;
      },
      defaultJSON: { choices: ["1", "2", "3", "4", "5", "6", "7"] },
      isFit: question => {
        return question.getType() === "discreteslider";
      },
      activatedByChanged: activatedBy => {
        Survey.JsonObject.metaData.addClass(
          "discreteslider",
          [
            { name: "hasOther", visible: false },
            { name: "choicesMax", visible: false },
            { name: "choicesMin", visible: false },
            { name: "choicesStep", visible: false },
            { name: "showOptionsCaption", visible: false },
            { name: "otherText", visible: false },
            { name: "optionsCaption", visible: false },
            { name: "otherErrorText", visible: false },
            { name: "storeOthersAsComment", visible: false },
            { name: "renderAs", visible: false },
            { name: "select2Config", visible: false }
          ],
          null,
          "dropdown"
        );
        Survey.JsonObject.metaData.addProperty("discreteslider", {
          name: "leftLabel",
          type: "string"
        });
        Survey.JsonObject.metaData.addProperty("discreteslider", {
          name: "rightLabel",
          type: "string"
        });
      },
      isDefaultRender: false,
      htmlTemplate: `<div><span class="left-label"></span><select class="discreteslider"></select><span class="right-label"></span></div>`,
      afterRender: (question, el) => {
        el.style.marginTop = "15px";
        el.style.marginBottom = "3px";
        let valueChangingByWidget = false;
        const $el = $(el);
        if (
          question.choices.length > 0 &&
          typeof question.choices[0] === "object"
        ) {
          question.leftLabel = question.choices[0].text;
        }
        if (
          question.choices.length > 1 &&
          typeof question.choices[question.choices.length - 1] === "object"
        ) {
          question.rightLabel =
            question.choices[question.choices.length - 1].text;
        }
        $el.find("span.left-label").html(question.leftLabel);
        $el.find("span.right-label").html(question.rightLabel);
        $el
          .find("select")
          .html(
            question.visibleChoices.map(
              c => `<option value="${c.value}">${c.text}}</option>`
            )
          );
        $el.find("select").barrating("show", {
          theme: "bars-movie",
          initialRating: "none",
          showValues: false,
          showSelectedRating: false,
          onSelect: (value, text) => {
            valueChangingByWidget = true;
            question.value = value;
            valueChangingByWidget = false;
          }
        });
        question.valueChangedCallback = () => {
          if (
            !valueChangingByWidget &&
            $el.find("select")[0].value !== question.value
          ) {
            $el.find("select").barrating("set", question.value);
          }
        };
        question.onPropertyChanged.add((sender, options) => {
          if (options.name === "leftLabel") {
            if (question.choices.length > 0) {
              question.choices[0] = {
                text: question.leftLabel,
                value:
                  typeof question.choices[0] === "number"
                    ? question.choices[0]
                    : question.choices[0].value
              };
            }
            $el.find("span.left-label").html(question.leftLabel);
          } else if (options.name === "rightLabel") {
            if (question.choices.length > 1) {
              const lastChoice = question.choices[question.choices.length - 1];
              question.choices[question.choices.length - 1] = {
                text: question.rightLabel,
                value:
                  typeof lastChoice === "number" ? lastChoice : lastChoice.value
              };
            }
            $el.find("span.right-label").html(question.rightLabel);
          }
        });
      },
      willUnmount: (question, el) => {
        const $el = $(el).find("select");
        $el.barrating("destroy");
        question.valueChangedCallback = undefined;
        // TODO: Remove onPropertyChanged event hander if performance
        // degradation over time is noticeable
      },
      pdfQuestionType: "dropdown"
    },
    "discreteslider"
  );
};
