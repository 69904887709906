import * as vernacularService from "../services/vernacular";
export const additionalPropertiesByObjectType = {
  survey: [
    {
      name: "name",
      isRequired: true,
      unshift: true
    },
    {
      name: "customer"
    },
    {
      name: "notes",
      type: "text"
    }
  ],
  page: [
    {
      name: "questionNumberPrefix",
      type: "string"
    }
  ],
  panel: [
    {
      name: "questionNumberPrefix",
      type: "string"
    },
    {
      name: "notes",
      type: "text"
    }
  ],
  question: [
    {
      name: "questionNumber",
      type: "string"
    },
    {
      name: "methodology",
      choices: async (question, callback) => {
        const vernacular = await vernacularService.getSurveyVernacular();
        callback(vernacular.methodologies.map(m => m.name));
      }
    },
    {
      name: "capability",
      dependsOn: "methodology",
      choices: async (question, callback) => {
        if (!!question) {
          const vernacular = await vernacularService.getSurveyVernacular();
          const { capabilities } = vernacular.methodologies.find(
            m => m.name === question.methodology
          );
          callback(
            capabilities
              .filter(
                c =>
                  !c.questionTypeWhitelist ||
                  c.questionTypeWhitelist.includes(question.getType())
              )
              .map(c => c.name)
          );
        }
      }
    },
    {
      name: "indicator",
      dependsOn: ["methodology", "capability"],
      choices: async (question, callback) => {
        if (!!question) {
          const vernacular = await vernacularService.getSurveyVernacular();
          const { capabilities } = vernacular.methodologies.find(
            m => m.name === question.methodology
          );
          const { indicators } = capabilities.find(
            c => c.name === question.capability
          );
          callback(
            indicators
              .filter(
                i =>
                  !i.questionTypeWhitelist ||
                  i.questionTypeWhitelist.includes(question.getType())
              )
              .map(i => i.name)
          );
        }
      }
    },
    {
      name: "postfix",
      type: "string"
    },
    {
      name: "ancestor",
      type: "string"
    },
    {
      name: "notes",
      type: "text"
    }
  ],
  itemvalue: [{ name: "notes" }],
  multipletextitem: [{ name: "notes", type: "text" }]
};

export const additionalModalEditorPropertiesByObjectTypeAndTab = {
  survey: {
    general: ["name", "customer", "notes"]
  },
  page: {
    general: ["questionNumberPrefix"]
  },
  panel: {
    general: ["questionNumberPrefix", "description", "notes"]
  },
  question: {
    general: [
      "description",
      "methodology",
      "capability",
      "indicator",
      "postfix",
      "ancestor",
      "notes"
    ]
  },
  multipletextitem: {
    general: ["notes"]
  }
};

export const hiddenPropertiesByObjectType = {
  survey: [
    "calculatedValues",
    "description",
    "isSinglePage",
    "questionTitleLocation",
    "questionTitleTemplate",
    "requiredText",
    "showPageNumbers",
    "showPageTitles",
    "showQuestionNumbers",
    "showTitle"
  ],
  page: [
    "enableIf",
    "name",
    "questionTitleLocation",
    "questionsOrder",
    "readOnly",
    "visible",
    "visibleIf"
  ],
  panel: [
    "indent",
    "innerIndent",
    "isRequired",
    "requiredErrorText",
    "startWithNewLine",
    "state"
  ],
  question: [
    "indent",
    "isRequired",
    "startWithNewLine",
    "title",
    "titleLocation",
    "width"
  ],
  comment: ["rows", "placeHolder"],
  multipletext: ["itemSize", "colCount"],
  multipletextitem: [
    "isRequired",
    "inputType",
    "maxLength",
    "placeHolder",
    "requiredErrorText",
    "validators"
  ],
  selectbase: ["choicesOrder", "choicesVisibleIf"],
  checkboxbase: [
    "commentText",
    "hasComment",
    "hasOther",
    "otherText",
    "otherPlaceHolder"
  ],
  checkbox: ["hasNone", "noneText", "hasSelectAll", "selectAllText"],
  matrixdropdowncolumn: ["cellType", "isRequired"],
  matrixdropdownbase: ["cellType", "columnMinWidth"],
  matrix: ["rowsOrder", "showHeader"],
  itemvalue: ["required"],
  sortablelist: ["maxAnswersCount", "emptyText", "useDefaultTheme"]
};

export const excludedPropertiesByObjectType = {
  survey: [
    "checkErrorsMode",
    "clearInvisibleValues",
    "completeText",
    "completedBeforeHtml",
    "completedHtml",
    "completedHtmlOnCondition",
    "cookieName",
    "firstPageIsStarted",
    "focusFirstQuestionAutomatic",
    "focusOnFirstError",
    "goNextPageAutomatic",
    "loadingHtml",
    "locale",
    "maxOthersLength",
    "maxTextLength",
    "maxTimeToFinish",
    "maxTimeToFinishPage",
    "mode",
    "pageNextText",
    "pagePrevText",
    "progressBarType",
    "questionErrorLocation",
    "questionStartIndex",
    "questionsOrder",
    "sendResultOnPageNext",
    "showCompletedPage",
    "showNavigationButtons",
    "showPrevButton",
    "showProgressBar",
    "showTimerPanel",
    "showTimerPanelMode",
    "startSurveyText",
    "storeOthersAsComment",
    "triggers"
  ],
  page: [
    "enableIf",
    "maxTimeToFinish",
    "navigationButtonsVisibility",
    "readOnly"
  ],
  question: [
    "correctAnswer",
    "enableIf",
    "maxLength",
    "readOnly",
    "requiredErrorText",
    "requiredIf",
    "size",
    "useDisplayValuesInTitle",
    "validators",
    "valueName",
    "visible",
    "visibleIf"
  ],
  text: ["inputType", "maxLength", "size", "placeHolder"],
  selectbase: [
    "choicesByUrl",
    "choicesEnableIf",
    "hideIfChoicesEmpty",
    "otherErrorText",
    "storeOthersAsComment",
    "visible"
  ],
  checkboxbase: [],
  checkbox: [],
  radiogroup: ["showClearButton"],
  multipletext: [],
  comment: ["cols", "maxLength"],
  matrixdropdown: ["totalText", "rowsVisibleIf"],
  matrixdropdownbase: [
    "choices",
    "columnColCount",
    "columnLayout",
    "enableIf",
    "horizontalScroll",
    "optionsCaption"
  ],
  matrixbase: ["columnsVisibleIf", "rowsVisibleIf"],
  matrix: ["cells", "isAllRowRequired"]
};
