import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// Since the app bundle is served from a CloudFront distribution over a static
// S3 website origin, registering the service worker is needed in order to
// intercept navigation requests to non-root URLs and serve the precached
// index.html file. See https://stackoverflow.com/a/43963261 for other
// workarounds.
// Warning: When the service worker is active, app updates require closing all
// browser tabs running the app and re-opening the app.
// See https://github.com/facebook/create-react-app/issues/5316
serviceWorker.register();
