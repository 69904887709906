import React, { PureComponent } from "react";
import Styles from "./Snackbar.module.css";

export class Snackbar extends PureComponent {
  render() {
    const { isOpen, message } = this.props;
    const className = isOpen
      ? [Styles.snackbar, Styles.show].join(" ")
      : Styles.snackbar;
    return <div className={className}>{message}</div>;
  }
}
