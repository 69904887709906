import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { LinkContainer } from "react-router-bootstrap";
import TimeAgo from "react-timeago";
import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import partition from "lodash/partition";
import slugify from "slugify";
import uuid from "uuid/v4";
import appState from "../App.state";
import { createSurveyWorkbook } from "../lib/workbookFactory";
import * as surveyService from "../services/survey";
import "./Home.css";
import { downloadDataAsFile } from "../lib/io";

/**
 * @typedef {import("mcg-survey-rest-api").Survey} Survey
 * @typedef {import("mcg-survey-rest-api").Element} Element
 */

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /**
       * @type {Survey[]}
       */
      surveys: []
    };
  }

  componentDidMount() {
    appState.addPromise(
      surveyService.getSurveys().then(surveys => {
        this.setState({
          surveys: orderBy(surveys, ["createdAt"], ["desc"])
        });
      })
    );
  }

  handleDownloadClick = id => () => {
    const survey = this.state.surveys.find(s => s.id === id);
    const workbook = createSurveyWorkbook(survey);
    const filename = `${slugify(survey.model.name, "_")}.xlsx`;
    appState.addPromise(
      workbook.xlsx.writeBuffer().then(buffer => {
        downloadDataAsFile(filename, buffer, {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
      })
    );
  };

  handleDuplicateClick = id => () => {
    const survey = this.state.surveys.find(s => s.id === id);
    const duplicateSurvey = cloneDeep(survey);
    const duplicateSurveyId = uuid();
    duplicateSurvey.name = duplicateSurvey.model.name = `Unnamed Survey From ${survey.name}`;
    appState.addPromise(
      surveyService
        .upsertSurvey(duplicateSurveyId, duplicateSurvey.model)
        .then(() => surveyService.getSurvey(duplicateSurveyId))
        .then(createdSurvey => {
          this.setState(state => ({
            surveys: [createdSurvey, ...state.surveys]
          }));
        })
    );
  };

  handleDeleteClick = id => () => {
    appState.addPromise(
      surveyService.deleteSurvey(id).then(() => {
        this.setState(state => ({
          surveys: state.surveys.map(s =>
            s.id === id
              ? Object.assign({}, s, {
                  updatedAt: Date.now(),
                  tags: { ...s.tags, deleted: "true" }
                })
              : s
          )
        }));
      })
    );
  };

  handleRestoreClick = id => () => {
    const survey = this.state.surveys.find(s => s.id === id);
    appState.addPromise(
      surveyService.upsertSurvey(id, survey.model).then(() => {
        this.setState(state => ({
          surveys: state.surveys.map(s =>
            s.id === id
              ? Object.assign({}, s, {
                  updatedAt: Date.now(),
                  tags: { ...s.tags, deleted: "false" }
                })
              : s
          )
        }));
      })
    );
  };

  render() {
    const [deletedSurveys, surveys] = partition(
      this.state.surveys,
      s => s.tags.deleted === "true"
    );
    return (
      <div className="Home">
        <Jumbotron>
          <Container>
            <h3>Manage your surveys</h3>
            <p>
              Below you can see the list of available surveys you can edit and
              preview
            </p>
            <p>
              <LinkContainer exact to={`/survey/${uuid()}/edit`}>
                <Button variant="primary">New</Button>
              </LinkContainer>
            </p>
          </Container>
        </Jumbotron>
        <Container className="table-responsive survey-listing-container">
          <Tabs variant="pills" defaultActiveKey="survey-listing">
            <Tab eventKey="survey-listing" title="Surveys">
              <Table borderless hover>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Customer</th>
                    <th>Created</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {surveys.map((survey, i) => (
                    <tr key={survey.id}>
                      <td>{survey.model.name}</td>
                      <td>{survey.model.customer}</td>
                      <td>
                        <TimeAgo
                          minPeriod={30}
                          formatter={(v, unit, suffix, epochMs, next) =>
                            unit === "second"
                              ? "Just now"
                              : next(v, unit, suffix, epochMs)
                          }
                          date={survey.createdAt}
                        />
                      </td>
                      <td className="action-col">
                        <ButtonGroup size="sm">
                          <Button
                            variant="outline-secondary"
                            onClick={this.handleDownloadClick(survey.id)}
                          >
                            <span
                              className="oi oi-data-transfer-download"
                              title="Download"
                              aria-hidden="true"
                            />
                          </Button>
                          <Button
                            variant="info"
                            onClick={this.handleDuplicateClick(survey.id)}
                          >
                            <span
                              className="oi oi-clipboard"
                              title="Duplicate"
                              aria-hidden="true"
                            />
                          </Button>
                          <LinkContainer exact to={`/survey/${survey.id}/edit`}>
                            <Button variant="primary">
                              <span
                                className="oi oi-pencil"
                                title="Edit"
                                aria-hidden="true"
                              />
                            </Button>
                          </LinkContainer>
                          <Button
                            variant="danger"
                            onClick={this.handleDeleteClick(survey.id)}
                          >
                            <span
                              className="oi oi-trash"
                              title="Delete"
                              aria-hidden="true"
                            />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="snapshot-listing" title="Snapshots">
              <Table borderless hover>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Customer</th>
                    <th>Created</th>
                    <th>Deleted</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {deletedSurveys.map((survey, i) => (
                    <tr key={survey.id}>
                      <td>{survey.model.name}</td>
                      <td>{survey.model.customer}</td>
                      <td>
                        <TimeAgo
                          minPeriod={30}
                          formatter={(v, unit, suffix, epochMs, next) =>
                            unit === "second"
                              ? "Just now"
                              : next(v, unit, suffix, epochMs)
                          }
                          date={survey.createdAt}
                        />
                      </td>
                      <td>
                        <TimeAgo
                          minPeriod={30}
                          formatter={(v, unit, suffix, epochMs, next) =>
                            unit === "second"
                              ? "Just now"
                              : next(v, unit, suffix, epochMs)
                          }
                          date={survey.updatedAt}
                        />
                      </td>
                      <td className="action-col">
                        <ButtonGroup size="sm">
                          <Button
                            variant="outline-secondary"
                            onClick={this.handleDownloadClick(survey.id)}
                          >
                            <span
                              className="oi oi-data-transfer-download"
                              title="Download"
                              aria-hidden="true"
                            />
                          </Button>
                          <Button
                            variant="info"
                            onClick={this.handleRestoreClick(survey.id)}
                          >
                            <span
                              className="oi oi-action-undo"
                              title="Restore"
                              aria-hidden="true"
                            />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </Container>
      </div>
    );
  }
}
