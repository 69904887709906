import * as SurveyJSCreator from "survey-creator";
import * as SurveyJS from "survey-knockout";
import "survey-creator/survey-creator.css";
import "survey-knockout/survey.css";

const defaultThemeColors = SurveyJS.StylesManager.ThemeColors["default"];
defaultThemeColors["$main-color"] = "#355D89";
defaultThemeColors["$main-hover-color"] = "#0A9C7F";
const defaultCreatorThemeColors =
  SurveyJSCreator.StylesManager.ThemeColors["default"];
defaultCreatorThemeColors["$primary-color"] = "#355D89";
defaultCreatorThemeColors["$primary-hover-color"] = "#0A9C7F";
defaultCreatorThemeColors["$secondary-color"] = "#355D89";
defaultCreatorThemeColors["$secondary-hover-color"] = "#0A9C7F";
SurveyJS.StylesManager.applyTheme();
SurveyJSCreator.StylesManager.applyTheme();
