import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { observer } from "mobx-react";
import { Snackbar } from "./components/Snackbar";
import { LoadingOverlay } from "./components/LoadingOverlay";
import Authenticate from "./pages/Authenticate";
import Home from "./pages/Home";
import SurveyEditor from "./pages/SurveyEditor";
import SurveyVernacularEditor from "./pages/SurveyVernacularEditor";
import appState from "./App.state";
import "bootstrap/dist/css/bootstrap.css";
import "open-iconic/font/css/open-iconic-bootstrap.css";
import "./App.css";

const withAuth = Component =>
  observer(props =>
    appState.isAuthenticated ? (
      <Component {...props} />
    ) : (
      <Redirect to="/authenticate" />
    )
  );

const App = () => (
  <Router>
    <Header />
    <Route exact path="/authenticate" component={Authenticate} />
    <Route exact path="/" component={withAuth(Home)} />
    <Route exact path="/survey/:id/edit" component={withAuth(SurveyEditor)} />
    <Route
      exact
      path="/vernacular"
      component={withAuth(SurveyVernacularEditor)}
    />
    <Footer />
    <ErrorSnackbar />
    <Loader />
  </Router>
);

const Loader = observer(() => appState.isLoading && <LoadingOverlay />);

const Header = observer(() => {
  return (
    <Navbar bg="dark" variant="dark" expand="xl" collapseOnSelect>
      <LinkContainer to="/">
        <Navbar.Brand>
          <div className="box">McChrystal Group</div>
        </Navbar.Brand>
      </LinkContainer>
      {appState.isAuthenticated && (
        <React.Fragment>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#" onClick={() => (appState.adminPasscode = "")}>
                Sign out
              </Nav.Link>
              <Nav.Link as={Link} href="#" to="/vernacular">
                Vernacular
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </React.Fragment>
      )}
    </Navbar>
  );
});

function Footer() {
  return (
    <footer className="site-info">
      <Container>© 2019 MCCHRYSTAL GROUP LLC. ALL RIGHTS RESERVED.</Container>
    </footer>
  );
}

const ErrorSnackbar = observer(() => {
  return (
    <Snackbar
      isOpen={!!appState.currentError}
      message={appState.currentError ? appState.currentError.message : ""}
    />
  );
});

export default App;
