import appState from "../App.state";

/**
 * @typedef {import("mcg-survey-rest-api").SurveyVernacular} SurveyVernacular
 */

const vernacularApiBaseUrl =
  process.env.REACT_APP_SURVEY_API_BASE_URL || "http://localhost:3000";

/**
 * @type {Promise<SurveyVernacular>}
 */
let cachedSurveyVernacular = null;
/**
 * @type {Promise<SurveyVernacular>}
 */
let loadingSurveyVernacular = null;

/**
 * Retrieve survey vernacular
 * @returns {Promise<SurveyVernacular>}
 */
export function getSurveyVernacular() {
  if (cachedSurveyVernacular) {
    return cachedSurveyVernacular;
  } else if (loadingSurveyVernacular) {
    return loadingSurveyVernacular;
  } else {
    loadingSurveyVernacular = fetch(
      `${vernacularApiBaseUrl}/vernacular/survey`,
      {
        headers: {
          Authorization: `Basic ${getAdminCredentials(appState.adminPasscode)}`
        }
      }
    )
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new UnexpectedStatusError(response.status, response.statusText);
        }
      })
      .then(vernacular => {
        cachedSurveyVernacular = loadingSurveyVernacular;
        return vernacular;
      })
      .finally(() => {
        loadingSurveyVernacular = null;
      });
    return loadingSurveyVernacular;
  }
}

/**
 * Update survey vernacular
 * @returns {Promise<void>}
 */
export async function upsertSurveyVernacular(vernacular) {
  const response = await fetch(`${vernacularApiBaseUrl}/vernacular/survey`, {
    method: "PUT",
    body: JSON.stringify(vernacular),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAdminCredentials(appState.adminPasscode)}`
    }
  });
  if (response.status !== 204) {
    throw new UnexpectedStatusError(response.status, response.statusText);
  } else {
    cachedSurveyVernacular = Promise.resolve(vernacular);
  }
}

class UnexpectedStatusError extends Error {
  constructor(status, statusText) {
    super(`Unexpected response status: ${status} (${statusText})`);
    this.status = status;
    this.statusText = statusText;
  }
}

function getAdminCredentials(passcode) {
  return window.btoa(`admin:${passcode}`);
}
