import appState from "../App.state";
import queryString from "query-string";
/**
 * @typedef {import("mcg-survey-rest-api").Survey} Survey
 */

const surveyApiBaseUrl =
  process.env.REACT_APP_SURVEY_API_BASE_URL || "http://localhost:3000";

/**
 * Validate the given admin passcode
 * @param {string} passcode
 */
export async function validateAdminPasscode(passcode) {
  const response = await fetch(`${surveyApiBaseUrl}/vernacular/survey`, {
    headers: {
      Authorization: `Basic ${getAdminCredentials(passcode)}`
    }
  });
  if (response.status === 401) {
    return false;
  } else if (response.status !== 200) {
    const { message = response.statusText } = await readJSONBody(response);
    throw new UnexpectedStatusError(response.status, message);
  } else {
    return true;
  }
}
/**
 * Add or update a survey
 * @param {string} id
 * @param {Survey["model"]} model
 * @param {Object<string, string>} [tags]
 * @param {boolean} [force]
 */
export async function upsertSurvey(id, model, tags = {}, force = false) {
  const response = await fetch(`${surveyApiBaseUrl}/surveys/${id}`, {
    method: "PUT",
    body: JSON.stringify({
      name: model.name,
      model,
      tags,
      force
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${getAdminCredentials(appState.adminPasscode)}`
    }
  });
  if (response.status === 409) {
    const err = await response.json();
    throw err.name === "ConflictingQuestionError"
      ? new ConflictingQuestionError(err.message)
      : new Error(err.message);
  } else if (response.status !== 204) {
    const { message = response.statusText } = await readJSONBody(response);
    throw new UnexpectedStatusError(response.status, message);
  }
}

/**
 * Retrieve all surveys
 * @param {Object<string, string>} [tags]
 */
export async function getSurveys(tags = {}) {
  const withTags = Object.keys(tags).length > 0;
  const query = withTags ? `?${queryString.stringify(tags)}` : "";
  const response = await fetch(`${surveyApiBaseUrl}/surveys${query}`, {
    headers: {
      Authorization: `Basic ${getAdminCredentials(appState.adminPasscode)}`
    }
  });
  if (response.status !== 200) {
    const { message = response.statusText } = await readJSONBody(response);
    throw new UnexpectedStatusError(response.status, message);
  }
  return response.json();
}

/**
 * Retrieve a survey by id
 * @param {string} id
 */
export async function getSurvey(id) {
  const response = await fetch(`${surveyApiBaseUrl}/surveys/${id}`, {
    headers: {
      Authorization: `Basic ${getAdminCredentials(appState.adminPasscode)}`
    }
  });
  if (response.status === 404) {
    return null;
  } else if (response.status !== 200) {
    const { message = response.statusText } = await readJSONBody(response);
    throw new UnexpectedStatusError(response.status, message);
  } else {
    return response.json();
  }
}

/**
 * Delete a survey by id
 * @param {string} id
 */
export async function deleteSurvey(id) {
  const response = await fetch(`${surveyApiBaseUrl}/surveys/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Basic ${getAdminCredentials(appState.adminPasscode)}`
    }
  });
  if (response.status !== 204) {
    const { message = response.statusText } = await readJSONBody(response);
    throw new UnexpectedStatusError(response.status, message);
  }
}

export class ConflictingQuestionError extends Error {}

class UnexpectedStatusError extends Error {
  constructor(status, statusText) {
    super(`Unexpected response status: ${status} (${statusText})`);
    this.status = status;
    this.statusText = statusText;
  }
}

function getAdminCredentials(passcode) {
  return window.btoa(`admin:${passcode}`);
}

function readJSONBody(response) {
  const isJson = response.headers
    .get("Content-Type")
    .includes("application/json");
  return isJson ? response.json() : Promise.resolve({});
}
