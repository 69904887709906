import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export class ConfirmationDialog extends Component {
  render() {
    const {
      show,
      title,
      body,
      callToAction,
      cancelLabel,
      confirmLabel,
      onCancel = () => {},
      onConfirm = () => {}
    } = this.props;
    return (
      <Modal show={show} onHide={onCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{body}</p>
          {callToAction && <p>{callToAction}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            {confirmLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
