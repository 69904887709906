import React, { Component } from "react";
import ReactDOM from "react-dom";
import Spinner from "react-bootstrap/Spinner";
import Styles from "./LoadingOverlay.module.css";

export class LoadingOverlay extends Component {
  constructor(props) {
    super(props);
    this.modalRoot = document.body;
    this.el = document.createElement("div");
    this.el.setAttribute("class", Styles.overlay);
  }

  componentDidMount() {
    this.modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(<Spinner animation="border" />, this.el);
  }
}
